<template>
  <div
    class="mx-auto w-full h-full"
    :class="{'max-w-md':compact}"
    @reset-form="resetForm"
  >
    <div class="h-full flex flex-col">
      <atoms-alert
        v-if="newAccountWarning"
        size="p1"
        type="warning"
        has-close
        :full-width="true"
        class="mb-6"
      >
        <strong>Hmm, we couldn't find an account with that email.</strong><br />
        Double-check your email and
        <span
          class="text-dark underline font-bold cursor-pointer"
          @click="resetForm"
        >try again</span> or
        register a new account.
      </atoms-alert>

      <h1 class="text-dark text-xl">
        <strong class="font-bold">Club<sup class="text-pink-600">+</sup></strong>
      </h1>
      <div class="py-2 mb-4 text-sm">
        Savings, discounts and more!
      </div>
      <template v-if="!isRegistrationAttempt">
        <AtomsButtonSSOTile
          v-for="SSO in activeSSOItems"
          :key="SSO"
          :sso-btn-pre-text="ssoBtnPreText"
          :sso-type="SSO"
          full-width
          class="mb-4"
          @click="socialAuthInit({ social_auth_type: SSO })"
        />
      </template>
      <p
        v-if="!isRegistrationAttempt"
        class="w-full text-center text-sm border-b border-solid border-gray-400 leading-[0.1em] mt-2.5 mb-5"
      >
        <span class="bg-white px-12 text-dark font-normal">
          OR
        </span>
      </p>
      <form @submit.prevent="authStepsHandler">
        <div class="grid grid-cols-1 gap-4">
          <template
            v-for="input in schema"
            :key="input.name">
            <div>
              <label :for="input.name" class="block mb-2 text-dark sm:text-base text-sm"><strong>{{input.label}}</strong></label>
              <AtomsInput
                v-model="model[input.name]"
                :validate="validateKey"
                v-bind="input"/>
            </div>
            <MoleculesFormPasswordMeter
              v-if="model.password && input.passwordMeter"
              :schema-obj="input"/>
          </template>
        </div>
        <div class="grid gap-4 mt-4" :class="{'grid-cols-2':isRegistrationAttempt, 'grid-cols-1':!isRegistrationAttempt}">
          <AtomsButtonBack
            v-if="isRegistrationAttempt"
            @click="resetForm"/>
          <atoms-button
            v-for="btn in formButtons"
            :key="btn.action"
            v-bind="btn"
            :enable-tracking="isRegistrationAttempt"
          >
            {{ btn.label }}
          </atoms-button>
        </div>
      </form>
      <div v-if="isLoginAttempt" class="text-right mb-4">
        <nuxt-link
          class="text-dark underline text-xs"
          to="/forgot-password/"
        >
          <strong class="font-bold">Forgot your password?</strong>
        </nuxt-link>
      </div>
      <p class="m-4 text-sm font-normal text-center text-dark">
        <template v-if="authRoute === 'login' && !hideAuthFooterLink">
          Not a member?
          <atoms-button
            id="auth_register_btn"
            class="p-0 !text-base !inline-flex"
            size="small"
            theme="tertiary"
            enable-tracking
            @click="redirectionHandler($event, '/register/')"
          >
            Join the Club
          </atoms-button>
        </template>
        <template v-if="authRoute === 'register' && !hideAuthFooterLink">
          Already a member?
          <atoms-button
            id="auth_login_btn"
            class="p-0 !text-base !inline-flex"
            size="small"
            theme="tertiary"
            enable-tracking
            @click="redirectionHandler($event, '/login/')"
          >
            Log in
          </atoms-button>
        </template>
      </p>
      <div
        v-if="isRegistrationAttempt"
        class="font-normal text-gray-80 text-xs text-center px-0 pb-6 leading-[1.125rem]"
      >
        By clicking 'JOIN THE CLUB', I acknowledge that I have read and agree to the
        <nuxt-link
          ref="terms_of_use"
          class="underline cursor-pointer"
          to="/terms-of-use/"
          target="_blank"
        >
          <strong>Terms of Use</strong>
        </nuxt-link>, the
        <nuxt-link
          ref="privacy_policy"
          to="/privacy-policy/"
          target="_blank"
          class="underline cursor-pointer"
        >
          <strong>Privacy Policy</strong>
        </nuxt-link> and the
        <nuxt-link
          ref="collection_notice"
          to="/collection-notice/"
          target="_blank"
          class="underline cursor-pointer"
        >
          <strong>Collection Notice</strong>
        </nuxt-link>.
      </div>
    </div>
  </div>
</template>

<script setup>
import memberhubConstants from '~/constants/memberhub.constants'
import { passwordValidator, emailValidator } from '~/helpers/validator'
defineOptions({
  name: 'OrganismsAuthentication'
})
const { $tracking, $bus } = useNuxtApp()
const route = useRoute()

const userStore = useUserStore()
const props = defineProps({
  authRoute: {
    type: String,
    required: true,
    validator (val) {
      return ['login', 'register'].includes(val)
    }
  },
  compact: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['reset-form', 'user-validation','ps-registration'])

const newAccountWarning = ref(false)
const hideAuthFooterLink = ref(false)

const schema = ref([
  {
    name: 'email',
    type: 'text',
    theme: 'light',
    placeholder: 'Email',
    label: 'Email',
    isSuccess:true,
    darkPlaceholder:true,
    modelType: 'text',
    hasPercentage: false,
    hasCurrency: false,
    errorMsg: 'Please enter a valid email address.'
  }
])

const model = ref({
  email: null
})
const isLoginAttempt = ref(false)
const isRegistrationAttempt = ref(false)
const registrationSchema = ref([
  {
    name: 'given_name',
    type: 'text',
    theme: 'light',
    placeholder: 'First Name',
    label: 'First Name',
    isSuccess:true,
    darkPlaceholder:true,
    modelType: 'text',
    hasPercentage: false,
    hasCurrency: false
  },
  {
    name: 'family_name',
    type: 'text',
    theme: 'light',
    placeholder: 'Family Name',
    label: 'Family Name',
    isSuccess:true,
    darkPlaceholder:true,
    modelType: 'text',
    hasPercentage: false,
    hasCurrency: false
  },
  {
    name: 'password',
    type: 'password',
    theme: 'light',
    placeholder: 'Password',
    label: 'Password',
    isSuccess:true,
    darkPlaceholder:true,
    modelType: 'text',
    hasPercentage: false,
    hasCurrency: false,
    passwordMeter: true
  }
])
const registrationModel = ref({
  given_name: null,
  family_name: null,
  password: null
})
const formButtons = computed(() => {
  if (isLoginAttempt.value) {
    return [
      {
        type: 'submit',
        action: 'submit',
        size: 'medium',
        label: 'LOG IN'
      }
    ]
  }
  if (isRegistrationAttempt.value) {
    return [
      {
        type: 'submit',
        action: 'submit',
        size: 'medium',
        label: 'JOIN THE CLUB'
      }
    ]
  }
  return [
    {
      type: 'submit',
      action: 'submit',
      size: 'medium',
      label: 'CONTINUE'
    }
  ]
})

const accountSetupButtons = ref([
  {
    type: 'button',
    action: 'back',
    theme: 'default',
    size: 'medium',
    label: 'BACK'
  },
  {
    type: 'submit',
    action: 'submit',
    size: 'medium',
    class: 'whitespace-nowrap',
    label: 'JOIN THE CLUB'
  }
])

const isLoginPage = computed(() => {
  return props.authRoute === 'login'
})

const activeSSOItems = computed(() => {
  return [memberhubConstants.GOOGLE_AUTH, memberhubConstants.FACEBOOK_AUTH]
})

const ssoBtnPreText = computed(() => {
  return isLoginPage.value ? 'Continue' : 'Join'
})

const modalAuth = computed(() => {
  return route.name === 'health-insurance-quotes-results'
})

/*
 * Email prefill handler
 * This method prefills an email coming from route query
 */
function prefillEmail () {
  const tmpEmail = route.query.email
  if (tmpEmail) {
    model.value.email = tmpEmail
  }
}
const vaultStore = useVaultStore()

/*
 * Handles the authentication steps based on the user's current action.
 * This function determines whether the user is attempting to log in, register, or verify purchase status.
 * For login and registration attempts, it calls the respective methods in the `userStore` and emits an 'authenticated' event upon success.
 * If neither login nor registration is being attempted, it checks if the user has previously made a purchase using their email.
 * If the purchase is verified, it emits a 'ps-registration' event with the user's name and email.
 * If the user's purchase cannot be verified or an error occurs, it calls `userExistsHandler` to handle the next steps.
 *
 * @param {Object} payload - The data payload for the authentication step, which varies based on the action (login, registration, etc.).
 */
function authStepsHandler () {
  if(!validateForm()) return
  if (isLoginAttempt.value) {
    userStore.login({ formData:{ email: model.value.email, password: model.value.password },schema:schema.value })
      .then(() => {
        $bus.emit('authenticated')
      })
  } else if (isRegistrationAttempt.value) {
    userStore.register({ formData: { email: model.value.email, given_name: model.value.given_name, family_name: model.value.family_name, password: model.value.password },schema:schema.value })
      .then(() => {
        validatedUserRedirectionHandler()
      })
  } else {
    vaultStore.checkPurchasedUser(model.value.email)
      .then(res => {
        if(res.is_valid) {
          emits('ps-registration', { name:res.given_name,email:model.value.email })
        } else {
          userExistsHandler(model.value.email)
        }
      })
      .catch(() => {
        userExistsHandler(model.value.email)
      })
  }
}
/*
 * Handles the existence check of a user by their email.
 * This function calls `userStore.checkUserExistence` with the provided email to check if the user exists.
 * Upon receiving a response, it forwards the result to `authTypesHandler` to determine the next steps based on the user's existence.
 * If an error occurs during the check, it calls `authTypesHandler` without any arguments, indicating a fallback or error state.
 *
 * @param {string} email - The email address to check for an existing user.
 */
function userExistsHandler (email) {
  userStore.checkUserExistence(email)
    .then(res => {
      authTypesHandler(res.authentication)
    })
    .catch(() => {
      authTypesHandler()
    })
}

/*
 * Authentication type handler with respect to email check response
 * This method checks the auth type and handles / passes user to desired authentication
 * @param authType
 */
function authTypesHandler (authType) {
  switch (authType) {
    case 'password':
      hideAuthFooterLink.value = true
      isLoginAttempt.value = true
      isRegistrationAttempt.value = false
      schema.value.push({
        name: 'password',
        type: 'password',
        theme: 'light',
        placeholder: 'Password',
        label: 'Password',
        isSuccess:true,
        darkPlaceholder:true,
        modelType: 'text',
        hasPercentage: false,
        hasCurrency: false,
        errorMsg: 'Please enter a valid password.'
      })
      model.value.password = null
      break
    case 'google':
      socialAuthInit({ social_auth_type: memberhubConstants.GOOGLE_AUTH })
      break
    case 'facebook':
      socialAuthInit({ social_auth_type: memberhubConstants.FACEBOOK_AUTH })
      break
    case 'guest':
      userStore.guestPasswordResetHandler({ formData:{ email: model.value.email } })
      navigateTo({
        path: '/guest/welcome-back/',
        query: {
          email: model.value.email
        }
      })
      break
    default:
      hideAuthFooterLink.value = true
      newAccountWarning.value = route.name === 'login'
      isLoginAttempt.value = false
      isRegistrationAttempt.value = true
      schema.value.push(...registrationSchema.value)
      model.value = { ...model.value, ...registrationModel.value }
      formButtons.value = accountSetupButtons.value
      break
  }
  nextTick().then(() => {
    prefillEmail()
  })
}

/*
 * Form reset handler
 */
function resetForm () {
  const email = model.value.email
  nextTick().then(() => {
    newAccountWarning.value = false
    model.value.email = email
    hideAuthFooterLink.value = !!modalAuth.value

    isLoginAttempt.value = false
    isRegistrationAttempt.value = false
    schema.value = [{
      name: 'email',
      type: 'text',
      theme: 'light',
      placeholder: 'Email',
      label: 'Email',
      isSuccess:true,
      darkPlaceholder:true,
      modelType: 'text',
      hasPercentage: false,
      hasCurrency: false,
      errorMsg: 'Please enter a valid email address.'
    }]
    model.value = {
      email: null
    }
    formButtons.value = [{
      type: 'submit',
      action: 'submit',
      size: 'medium',
      label: 'JOIN THE CLUB'
    }]

  })
}

function redirectionHandler (e, path) {
  if (route.name === 'health-insurance-quotes-results') {
    resetForm()
  } else {
    $tracking.click(e, path)
    $bus.emit('redirection', { path })
  }
}

/*
 * SSO initialize
 * @param payload
 */
function socialAuthInit (payload) {
  userStore.socialAuthInit(payload)
}

/*
 * Redirection handler for button action in validated user case
 * @param e
 */
function validatedUserRedirectionHandler () {
  const isOnboarding = !userStore.getUserProfile?.profile?.onboardingCompleted && !userStore.getUserProfile?.profile?.onboardingSkipped
  const defaultPage = isOnboarding ? '/dashboard/onboarding/' : '/dashboard/'
  const path = modalAuth.value ? (userStore.getAuthUserSourcePage || defaultPage) : defaultPage
  const query = modalAuth.value ? (userStore.getAuthUserSourcePageQuery || {}) : {}
  $bus.emit('authenticated')
  $bus.emit('redirection', { path, query })
}

onMounted(() => {
  nextTick().then(() => {
    prefillEmail()
    if (route.name === 'health-insurance-quotes-results') {
      hideAuthFooterLink.value = true
    }
  })
})

/*
 * Validates the entire form by iterating over each input in the schema and checking its validity.
 *
 * @returns {boolean} - Returns true if all inputs are valid, otherwise false.
 */
function validateForm () {
  let valid = true
  schema.value.forEach(input => {
    if(!validateKey({ target: { name: input.name } })){
      valid = false
    }
  })
  return valid
}

/*
 * Validates a form input based on its key and updates the input object with the validation results.
 *
 * @param {Event} e - The event object containing the target input element.
 * @returns {boolean} - Returns true if the input is valid, otherwise false.
 */
function validateKey (e) {
  const key = e?.target?.name
  const input = schema.value.find(formElement => formElement.name === key)
  input.errorMessage = ''
  input.isSuccess = false
  const value = model.value[key]

  if (!value) {
    input.errorMessage = input.errorMsg || 'This field is required.'
  } else if (input.passwordMeter && value) {
    validatePassword(input, value)
  } else if (key === 'email' && value && !emailValidator(value)) {
    input.errorMessage = input.errorMsg || 'Please enter a valid email address.'
    input.isSuccess = false
  } else {
    input.isSuccess = true
  }
  return input.isSuccess
}

/*
 * Validates the password based on various criteria and updates the input object with the validation results.
 *
 * @param {Object} input - The input object that will be updated with the validation results.
 * @param {string} value - The password value to be validated.
 */
function validatePassword (input, value) {
  const result = {}

  // Check if the password meets the minimum length requirement of 8 characters
  const eightCharCheck = passwordValidator(value, /^.{8,}$/)

  // Check if the password meets the minimum length requirement of 6 characters
  const sixCharCheck = passwordValidator(value, /^.{6,}$/)

  // Check if the password contains at least one lowercase letter
  const lowercaseCheck = passwordValidator(value, /[a-z]/)

  // Check if the password contains at least one uppercase letter
  const uppercaseCheck = passwordValidator(value, /[A-Z]/)

  // Check if the password contains at least one number
  const numberCheck = passwordValidator(value, /\d/)

  // Check if the password contains at least one special character
  const specialCharCheck = passwordValidator(value, /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/)

  // Get the list of unmet password requirements
  result.passwordMeterRequires = getPasswordMeterRequirements(eightCharCheck, lowercaseCheck, uppercaseCheck, specialCharCheck, numberCheck)

  // Count the number of conditions satisfied
  const conditionsSatisfied = [lowercaseCheck, uppercaseCheck, numberCheck, specialCharCheck].filter(Boolean).length

  // Determine the password strength and validity based on the checks
  if (eightCharCheck && conditionsSatisfied === 4) {
    result.valid = true
    result.passwordMeter = memberhubConstants.PASSWORD_METER.STRONG
    result.passwordMeterRequires = []
  } else if (eightCharCheck && conditionsSatisfied >= 3) {
    result.valid = true
    result.passwordMeter = memberhubConstants.PASSWORD_METER.GOOD
  } else if (sixCharCheck && conditionsSatisfied >= 3) {
    result.valid = false
    result.passwordMeter = memberhubConstants.PASSWORD_METER.WEAK
  } else {
    result.passwordMeter = memberhubConstants.PASSWORD_METER.POOR
    result.valid = false
  }

  // Update the input object with the validation results
  input.error = {
    errorObj: result,
    status: true
  }
  input.isSuccess = result.valid
}

/*
 * Generates a list of password meter requirements that are not met.
 *
 * @param {boolean} eightCharCheck - Indicates if the password meets the minimum length requirement.
 * @param {boolean} lowercaseCheck - Indicates if the password contains at least one lowercase letter.
 * @param {boolean} uppercaseCheck - Indicates if the password contains at least one uppercase letter.
 * @param {boolean} specialCharCheck - Indicates if the password contains at least one special character.
 * @param {boolean} numberCheck - Indicates if the password contains at least one number.
 * @returns {Array<string>} - An array of unmet password requirements.
 */
function getPasswordMeterRequirements (eightCharCheck, lowercaseCheck, uppercaseCheck, specialCharCheck, numberCheck) {
  const requirements = []
  if (!eightCharCheck) {
    requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.MIN_LENGTH)
  }
  if (!lowercaseCheck) {
    requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.LOWERCASE)
  }
  if (!uppercaseCheck) {
    requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.UPPERCASE)
  }
  if (!specialCharCheck) {
    requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.SPECIAL_CHAR)
  }
  if (!numberCheck) {
    requirements.push(memberhubConstants.PASSWORD_METER_REQUIRED_KEYS.NUMBER)
  }
  return requirements
}

</script>

<style scoped>

</style>
